import React from 'react';
import SquareImage from './squareImage';
import {useSpring, animated} from 'react-spring'

const HomeSquareImage = ({src}) => {

    const transitionDelay = Math.floor(Math.random() * 10) * 100;
    const props = useSpring({duration: 300, delay: transitionDelay, opacity: 1, transform: 'translateY(-2rem)', from: {opacity: 0, transform: 'translateY(0rem)'}});


    return(
        <animated.div style={props}>
            <SquareImage src={src}/>
        </animated.div>
    )
}

export default HomeSquareImage;