import React, { useState } from 'react';
import ReactPlayer from 'react-player'
import {useSpring, animated} from 'react-spring'

import volume from '../images/volume.svg';
import mute from '../images/mute.svg';

const Video =  ({url, videoPage}) => { 
  
  const [muted, setMute] = useState(true); 
  const props = useSpring({duration: 300, delay: 200, opacity: 1, transform: 'translateY(-2rem)', from: {opacity: 0, transform: 'translateY(0rem)'}});
  if(videoPage){
      return(<div className="relative"
          style={{paddingBottom: '56.25%'}} >
            <ReactPlayer 
              id="video"
              className="" 
              height="100%"
              width="100%"
              style={{ position:'absolute' }}
              url={url} 
              loop
              volume={1}
               />
        </div>)
  }else{
    return (
      <animated.div style={props} >
        <div className="relative"
          style={{paddingBottom: '56.25%'}} >
          <button 
            name="volume" 
            className="bg-white absolute bottom-0 ml-4 w-12 h-12 z-10 rounded-full pointer-events-auto" 
            style={{transform: 'translateY(-1rem)'}} 
            onClick={() => { 
              setMute(!muted)
               }}
            >{ muted ?
              (<img alt="volume icon" className="m-auto" src={mute} />) : 
              (<img alt="volume icon" className="m-auto" src={volume} />)
            }
          </button>
            <ReactPlayer 
              id="video"
              className="pointer-events-none" 
              height="100%"
              width="100%"
              style={{ position:'absolute' }}
              url={url} 
              playing
              loop
              muted={muted}
              volume={1}
               />
        </div>
    </animated.div>
    )
  }
}
export default Video;