import React from "react";

import HomeLayout from "../components/homelayout";
import SEO from "../components/seo";
import ChevronLink from "../components/chevronlink";
import title from "../images/title.svg";
import Video from "../components/video";
import Popup from "../components/popup";
import HomeSquareImage from "../components/homeSquareImage";
import { Link } from "gatsby";


function IndexPage() {
  return (
    <HomeLayout>
      <Popup />
      <SEO title="Reasons to stay" description="" />
      <section className="py-12">
        <div className="container mx-auto">
          <div className="flex flex-col items-center p-4 md:py-4">
            <img
              src={title}
              alt="Find your reasons to stay"
              className="w-10/12 sm:w-8/12 md:w-6/12 lg:w-4/12"
            />
          </div>
        </div>
      </section>
      <section className="py-4 relative overflow-x-hidden">
        <div className="container relative mx-auto">
          <div className="flex flex-col items-center p-4 md:py-4 z-40 relative">
            <div className="w-full md:w-8/12 z-2">
              <Video url="https://www.youtube.com/embed/UIQEVnLU9ck?rel=0&showinfo=0&ytp-pause-overlay=0" />
            </div>
            <div className="container mx-auto">
              <div className="flex items-center">
                <div className="w-full md:w-12/12 px-2 text-center">
                  <p className="text-base">
                    <Link to="/videos" className="underline">View and share the campaign videos</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="h-full absolute w-full top-0 mx-auto">
            <div className="relative h-full">
              <div
                className="absolute w-2/12 z-0 hidden md:block "
                style={{
                  left: "50%",
                  top: "50%",
                  transform: "translate(-340%, -50%)"
                }}
              >
                <HomeSquareImage src="4.jpg" />
              </div>
              <div
                className="absolute w-1/12 z-0 hidden md:block"
                style={{
                  left: "50%",
                  bottom: "2%",
                  transform: "translate(-695%, -60%)"
                }}
              >
                <HomeSquareImage src="2.jpg" />
              </div>
              <div
                className="absolute w-1/12 z-0 hidden md:block"
                style={{
                  left: "50%",
                  top: "10%",
                  transform: "translate(-550%, -0%)"
                }}
              >
                <HomeSquareImage src="6.jpg" />
              </div>
              <div
                className="absolute w-2/12 z-0 hidden md:block"
                style={{
                  right: "50%",
                  top: "50%",
                  transform: "translate(340%, -50%)"
                }}
              >
                <HomeSquareImage src="5.jpg" />
              </div>
              <div
                className="absolute w-1/12 z-0 hidden md:block"
                style={{
                  right: "50%",
                  bottom: "10%",
                  transform: "translate(520%, -30%)"
                }}
              >
                <HomeSquareImage src="3.jpg" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mx-auto">
          <div className="flex flex-col items-center p-4 md:pt-8 md:pb-16">
            <ChevronLink to="/having-thoughts">
              I've been having thoughts of suicide and would like help
            </ChevronLink>
            <ChevronLink to="/worried">
              I'm worried about someone at risk of suicide and need to know how
              to help
            </ChevronLink>
            <ChevronLink to="/lost-someone">
              I've lost someone to suicide and need help
            </ChevronLink>
            <ChevronLink to="/support-someone">
              I'd like to support someone who has lost a family member or friend
              to suicide
            </ChevronLink>
          </div>
        </div>
      </section>
    </HomeLayout>
  );
}

export default IndexPage;
