import React from "react";
import PropTypes from "prop-types";
import PreFooter from './prefooter'
import Footer from './footer';

function HomeLayout({ children }) {

  return (
    <div className="font-sans">

      <main className="md:justify-center">
          <div className="bg-darkRed py-4">
              <div className="w-3/4 text-center mx-auto">
                  <p className="text-white text-center">
                      Feeling worried or struggling to cope during the coronavirus pandemic? <br/>The Beyond Blue Coronavirus Mental Wellbeing Support Service can help 24/7. Call 1800 512 348 or visit the <a className="underline" href="https://coronavirus.beyondblue.org.au/" target="_blank">website here</a>
                  </p>
              </div>
          </div>
        {children}
      </main>

      <PreFooter></PreFooter>
      <Footer></Footer>
    </div>
  );
}

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default HomeLayout;
