import React, { useState } from 'react';
import close from '../images/close.svg';
import { useTransition, animated } from 'react-spring';

const Popup = () => {

    const [open, setOpen] = useState(true);
    const transitions = useTransition(open, null, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        })

        return transitions.map(({ item, key, props }) => 
        item
          ? <animated.div style={props} key={key}>
                <div className="pointer-events-none md:fixed md:bottom-0 md:z-50">
                    <div className="fixed w-full md:right-0 md:mr-8 top-0 md:top-auto md:bottom-0 md:mb-8 md:w-popup  z-50 pointer-events-none">
                        <div className="bg-primary w-full relative block p-6 h-full pointer-events-auto shadow-lg rounded-sm">
                        <button name="close-button" className="absolute right-0 top-0 z-30" onClick={() => {setOpen(false)}}><img alt="close" src={close} className="p-4"/></button>
                            <p className="text-white text-2xl md:pb-4">I'm in need of <br /><span className="whitespace-no-wrap">immediate support</span></p>
                            <a href="#prefooter" onClick={() => {setOpen(false)}} className="inline-block mt-4 md:mt-0 no-underline bg-white px-8 py-3 rounded-sm whitespace-no-wrap font-bold" >Find support now</a>
                        </div>
                    </div>
                    <div className="h-40 pointer-events-none">
                    </div>
                </div>
            </animated.div>
          : <animated.div style={props} key={key}>
          </animated.div>
        )

    return(open ? (
        <animated.div style={transition} >
        <div className="pointer-events-none md:fixed md:bottom-0 md:z-50">
            <div className="fixed w-full md:right-0 md:mr-8 top-0 md:top-auto md:bottom-0 md:mb-8 md:w-4/12 lg:w-3/12 xl:w-2/12 h-40  z-50 pointer-events-none">
                <div className="bg-dark w-full relative block p-4 h-full pointer-events-auto">
                <button name="close" className="absolute right-0 top-0 z-30" onClick={() => {setOpen(false)}}><img src={close} className="p-4"/></button>
                    <p className="text-white text-xl md:pb-4">I'm in need of <br /><span className="whitespace-no-wrap">immediate support</span></p>
                    <a href="#prefooter" onClick={() => {setOpen(false)}} className="inline-block mt-4 md:mt-0 no-underline bg-white px-8 py-3 rounded-sm whitespace-no-wrap" >Find support now</a>
                </div>
            </div>
            <div className="h-40 pointer-events-none">
            </div>
        </div>
        </animated.div>
    )
    : (
        <div></div>
    ))
}

export default Popup;